<!-- 监控车辆详情 -->
<template>
  <div class="monitorVehicleDetail padding20">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="车牌号码：" prop="plateNumber">
        <el-input v-model="form.plateNumber" :disabled="disabled"></el-input>
      </el-form-item>
      <el-form-item label="车牌颜色：" prop="plateColor">
        <el-select
          v-model="form.plateColor"
          :disabled="disabled"
          placeholder="请选择车牌颜色"
        >
          <el-option label="蓝色" value="0"></el-option>
          <el-option label="绿色" value="2"></el-option>
          <el-option label="黄色" value="1"></el-option>
          <el-option label="白色" value="5"></el-option>
          <el-option label="黑色" value="4"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="生效区域：" prop="area">
        <el-select
          v-model="form.area"
          :disabled="disabled"
          placeholder="请选择生效区域"
        >
          <el-option
            :label="areaInfo.extras.areaName"
            :value="areaInfo.extras.areaIds"
          ></el-option
        ></el-select>
      </el-form-item> -->
      <el-form-item label="备注：" prop="remark">
        <el-input
          type="textarea"
          maxlength="50"
          autosize
          v-model="form.remark"
          placeholder="请输入备注信息"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" v-show="!disabled"
          >提交</el-button
        >
        <el-button @click="$router.go(-1)" v-show="!disabled" style="margin-left:30px">取消</el-button>
        <el-button @click="$router.go(-1)" v-show="disabled">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getDt,
  // dateFormat,
  // setIndex,
  // exportExcelNew,
} from "../../../common/js/public.js";
export default {
  components: {},
  data() {
    // var validatePlateNum = (rule, value, callback) => {
    //   let a = 1;
    //   if (a === 1) {
    //     callback(new Error("这个车牌号已经存在了"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      form: {
        plateNumber: "",
        plateColor: "0",
        // area: "",
        remark: "",
      },
      disabled: false,
      monitorCarId: "",
      // areaInfo: {},
      data: {},
      rules: {
        plateNumber: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
          { min: 7, max: 8, message: "车牌号格式不正确", trigger: "blur" },
          // { validator: validatePlateNum, trigger: "blur" },
        ],
        plateColor: [
          { required: true, message: "请选择车辆颜色", trigger: "change" },
        ],
        // area: [
        //   {
        //     required: true,
        //     message: "请选择生效区域",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  // 方法集合
  methods: {
    onSubmit() {
      let self = this;
      self.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (self.data.carId) {
            self.updateData();
          } else {
            self.add();
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    updateData () {
        let method = 'post';
			  this.$axios[method]('/acb/2.0/monitorCar/update', {
          data: {
          monitorCarId: this.monitorCarId,
          plate: this.form.plateNumber,
          colorCode: this.form.plateColor,
          // areaId: this.form.area,
          description: this.form.remark,
        },
        }).then(res => {
          if (res.state == 0) {
            this.$alert("成功", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.$router.go(-1);
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
			  })
      },
    add () {
        let method = 'post';
			  this.$axios[method]('/acb/2.0/monitorCar/add', {
          data: {
          plate: this.form.plateNumber,
          colorCode: this.form.plateColor,
          // areaId: this.form.area,
          description: this.form.remark,
        },
        }).then(res => {
          if (res.state == 0) {
            this.$alert("成功", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.$router.go(-1);
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
			  })
      },
  },
  created() {
    // this.areaInfo = JSON.parse(sessionStorage.getItem("areaInfo"));
    // this.form.area = this.areaInfo.extras.areaIds;
  },
  mounted() {
    this.data = this.$route.query;
    if (this.data.carId) {
      this.form.plateNumber = this.data.plate;
      this.form.plateColor = String(this.data.colorCode);
      this.form.remark = this.data.description;
      this.disabled = this.data.disabled == 1;
      this.monitorCarId = this.data.monitorCarId;
    }
  },
};
</script>
<style lang="stylus" scoped>
//@import url(); 引入公共css类
.monitorVehicleDetail
  background #fff
  .el-form
    width: 350px;
    .el-button
      margin-left 0px
</style>
